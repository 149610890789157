import {HttpParams} from '@angular/common/http';

export function queryParams(params: any): HttpParams{
  let httpParams = new HttpParams();
  for (const key of Object.keys(params)){
    if (params[key] instanceof Array){
      for (const val of params[key]){
        httpParams = httpParams.append(key, val);
      }
    } else {
      httpParams = httpParams.append(key, params[key]);
    }
  }
  return httpParams;
}
