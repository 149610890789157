import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {queryParams} from '../../lib/queryParams';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  create(body: any): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(environment.apiUrl + '/wp-json/wc/v3/orders/' , body, httpOptions);
  }
  find(params = {}): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: queryParams(params),
      observe: 'response' as 'response'
    };
    return this.http.get(environment.apiUrl + '/wp-json/wc/v3/orders/' , httpOptions);
  }
  get(id: number): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(environment.apiUrl + '/wp-json/wc/v3/orders/' + id , httpOptions);
  }
}
