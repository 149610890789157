import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {queryParams} from '../../lib/queryParams';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, private route: Router) {}

  find(params: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: queryParams(params),
      observe: 'response' as 'response'
    };
    return this.http.get(environment.apiUrl + '/wp-json/wc/v3/products', httpOptions);
  }
  get(id: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(environment.apiUrl + '/wp-json/wc/v3/products/' + id, httpOptions);
  }
  getTag(name: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: {search: name}
    };
    return this.http.get(environment.apiUrl + '/wp-json/wc/v3/products/tags', httpOptions);
  }
  attributes(id: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(environment.apiUrl + '/wp-json/wc/v3/products/attributes/' + id, httpOptions);
  }
}
